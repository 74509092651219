import 'bootstrap/dist/css/bootstrap.min.css';


import './App.css';



const App = () => {




  return (
    <>
 <article>
      <h1>We will be back soon!</h1>
      <div>
        <p>Sorry for the inconvenience but we are performing some maintenance at the moment. If you need to you can always <a href="https://t.me/dexfactory">contact us at Telegram</a>, otherwise we'll be back online shortly!</p>
        <p>We have seen that our smart contract was not sustainable and 2 to 5% ROI was super risk. We want our platform should be sustainable and for run for long time to avoid some ponzinomics. We have promised trading platform but due to a lot of restriction we were unable to execute trade on daily bases. So kindly we are migrating all your data to new smart smart contract. Your rewards will remain the same as it was in the previous contract. You will able to continue with more sustainable DexFactory version in the upcoming upgrade.</p>
        <h3>Features in New Smart Contract</h3>
        <p>ROI will convert into Trading Profit % per day to distribute to our investor.</p>
        <p>None of our investor will depend on other investor funds</p>
        <p>Your rewards will be from trading not from the people investment.</p>
        <p>Every 7 days withdrawal with the 10% LIMIT of the total Volume Locked will be withdrawn by user. They will not able to withdraw more than 10% of the TVL in 7 days.</p>
        <p>Unstake Feature will be no more</p>
        <p>50% Funds of the smart contract will be traded on Different Exchanges with different bots</p>
        <p>Emergency withdraw request will be added so no fees will be applied on the user.</p>
        <p>Platform will convert into USDC from BNB. (Whatever amount you have invested on the new price it will be converted into USDC)</p>
        <p>Withdrawal, and Deposit stop policy</p>
        <p>Referral reward reduce to 0.5%. The deposit fee will reduce to 2%. Withdrawal fee will be remained same</p>
        <br />
        <h3>Migration process and website status</h3>
        <p>It will take maximum 4 to 7 days because we have a lot of date to move to new smart contract and our new platform is under process.</p>
        <p>For the Delay we will add some bonus to your investments</p>
        <p>Your funds are safe. It will be injected all in the USDC to the new contract</p>
        <br />
        <h3>Refund policy</h3>
        <p>If somebody is not satisfied with our new platform after usage. They can request us on the telegram. We will refund them.</p>
        <br />
      <h1>TEAM DEXFACTORY </h1>      
</div>
    </article>
    </>
  );
}

export default App;
